section#journey {
  h3 {
    color: var(--color2);
  }

  h4 {
    color: var(--color3);
  }

  .timeline-logo-container {
    width: 100%;
    height: 100%;
    top: 2px;
    position: relative;
    overflow: hidden;
  }

  .timeline-logo {
    display: block;
    position: absolute;
    max-width: 80%;
    max-height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .timeline-content {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .timeline-project-toggle {
    &.hidden {
      display: none;
    }
  }

  .timeline-project-container {
    padding-left: 2rem;
    padding-right: 2rem;

    &.hidden {
      display: none;
    }

    .timeline-project {
      padding: 0.5rem 0.5rem 0.5rem 0;
      font-size: 0.9rem;

      .strong {
        font-weight: 700;
      }
    }
  }

  .vertical-timeline-element-date {
    padding-bottom: 0;

    &.hidden {
      display: none;
    }
  }

  .vertical-timeline-element--job-with-icon .vertical-timeline-element-date {
    @media only screen and (min-width: 1170px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .vertical-timeline-element--work .vertical-timeline-element-date {
    @media only screen and (min-width: 1170px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .job-tech {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    
    .tech {
      padding: 0.25rem;
      text-align: center;
      margin: 0.5rem 0 0 0.25rem;
      width: 2rem;
      height: 2rem;
      fill: var(--timeline-tech-fill);
      background-color: var(--timeline-tech-bg);
      border: 1px solid var(--timeline-tech-border);
      border-radius: 50%;
    }
  }
}

.vertical-timeline-element--job .vertical-timeline-element-icon {
  @media only screen and (min-width: 1170px) {
    margin-left: -5px;
    margin-top: 24px;
  }

  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: 15px;
  margin-top: 18px;
}