section#contact {
  ul {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      display: flex;

      a {
        color: black;
        text-decoration: underline;
      }

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  section#contact {
    ul {
      svg {
        margin-bottom: 0.5rem;        
      }
    }
  }
}
