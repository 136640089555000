section#skills {
  .skill-category {
    margin-top: 1rem;
  }

  .skill-category:first-of-type {
    margin-top: 0;
  }

  .skills {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .skill {
    padding: 5px 10px;
    margin: 5px;
    color: var(--skill-text);
    border: 1px solid var(--skill-border);
    border-radius: 16px;
  }
}
