section#banner {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-items: space-around;
  height: 20rem;
  width: 100vw;

  .banner-text {
    color: var(--text);
    font-size: 1.5rem;
    margin: 0 auto 0 1rem;
  }

  .banner-image {
    border: 1px solid rgba(var(--banner-logo-border), 0.3);
    border-radius: 10rem;
    margin: 0 1rem 0 auto;
    overflow: hidden;
    width: 10rem;
    height: 10rem;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .banner-name {
    font-size: 2rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 820px) {
  section#banner {
    flex-direction: column;
    align-items   : center;
    justify-items : space-around;

    .banner-image {
      margin: auto 0 0.5rem 0;
      width: 8rem;
      height: 8rem;
    }

    .banner-text {
      margin    : 0.5rem 0 auto 0;
      text-align: center;
    }
  }
}