@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');

:root {
  --color1                 : #fdfaf9;
  --color2                 : #1f5677;
  --color3                 : #ee3144;

  --bg                     : var(--color1);
  --text                   : var(--color2);
  --heading                : var(--color3);

  // Menu
  --menu-height            : 4rem;
  --menu-bg                : var(--color2);
  --menu-left-text         : var(--color1);
  --menu-text              : var(--color1);
  --menu-text-hover        : var(--color1);

  // Banner
  --banner-logo-border     : var(--color2);

  // Timeline
  --timeline-item-bg       : #fff;
  --timeline-item-color    : var(--color2);
  --timeline-icon-bg       : #fff;
  --timeline-tech-bg       : var(--timeline-item-bg);
  --timeline-tech-fill     : var(--color2);
  --timeline-tech-border   : var(--color2);

  // Skills
  --skill-border           : var(--color2);
  --skill-text             : var(--color2);
}

*, html {
  box-sizing : border-box;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  padding    : 0;
  margin     : 0;
}

body {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 4rem;
  left: 0;
  scroll-behavior: smooth;
  overflow-y: scroll;
  background-color: var(--bg);

  section {
    padding: 0;
    margin : 0;
    margin-left: auto;
    margin-right: auto;

    h2 {
      color: var(--heading);
      font-size     : 1.5rem;
      padding-bottom: 0.75rem;
    }

    color: var(--text);

    &#banner {
      background: var(--bg);
    }

    .content {
      display: flex;
      flex-direction: column;
    }
    
    &:not(#banner) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      background: var(--bg);

      .content {
        margin: 0 auto;
        width : 60vw;
      }
    }
  }

  section:last-of-type {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 820px) {
  main {
    section:not(#banner) {
      .content {
        padding-left : 1.5rem;
        padding-right: 1.5rem;
        width        : 100vw;
      }
    }
  }  
}
