section#footer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .copyright {
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 820px) {
  section#footer {
    .copyright {
      margin-top: 1rem;
    }
  }
}