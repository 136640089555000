section#documents {
  .doc {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .doc-icon {
    display: inline-block;
    margin-top: 0.6rem;
    height: 24px;
    width: 24px;
  }

  .doc-title {
    margin-left: 0.4rem;
    display: inline-block;
  }

  .doc-info {
    flex-basis: 100%;
    margin-left: 2rem;
    margin-top: -0.6rem;
  }
}