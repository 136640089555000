.menu-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--menu-height);
  background-color: var(--menu-bg);
}

nav#menu {
  position: relative;
  width: 60vw;
  z-index: 1000;
  height: var(--menu-height);
  transition: height 400ms ease-in-out;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  svg {
    fill: var(--menu-text);

    &:hover, 
    &:focus {
      fill: var(--menu-text-hover);
    }
  }

  .logo {
    flex-grow: 1;

    .logo-text {
      color: var(--menu-left-text);
      padding: 1.5rem 0;
      font-weight: 600;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
    }
  }

  .navbar-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 4rem;
    line-height: 1rem;

    .nav-expand {
      display: none;
    }

    .nav-item {
      padding: 1rem 0;

      &.nav-item-contact {
        display: flex;

        a {
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 0.25rem;
        }
      }
    }

    .nav-link, .logo .logo-text {
      color: var(--menu-text);
      text-decoration: none;

      &:hover,
      &:focus {
        color: rgb(var(--menu-text-hover) / 0.8);
      }
    }

    li:last-of-type .link-text {
      padding-right: 0;
    }

    .link-text {
      font-weight: 500;
      padding: 0.5rem;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1024px) {
  nav#menu {
    width: 100vw;

    .navbar-nav {
      .nav-item {
        transition: all 1s ease;
      }

      .logo {
        margin-left: 1.5rem;
        padding: 0;
      }
      
      .nav-item-contact {
        margin-right: 1.5rem;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  nav#menu {
    &.expanded {
      height: 15.5rem;
      transition: height 400ms ease-in-out;
      flex-wrap: wrap;

      .navbar-nav {
        .nav-item {
          display: block;
          flex-basis: 100%;
          text-align: right;
          margin: 0;
          padding: 0.5rem 1.5rem 0.5rem 0;

          .link-text {
            padding: 0;
            margin: 0;
          }

          &.nav-item-contact {
            display: flex;
            justify-content: flex-end;
  
            a {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }

      .nav-expand {
        .nav-hamburger {
          .nav-hamburger-line {
            transform: translateX(1.5rem);
            background-color: transparent !important;

            &::before {
              transform: rotate(45deg) translate(-1rem, 1rem) !important;
            }

            &::after {
              transform: rotate(-45deg) translate(-1rem, -1rem) !important;
            }
          }
        }
      }
    }

    .navbar-nav {
      .nav-item {
        display: none;
        background-color: var(--menu-bg);

        &.nav-item-contact {
          display: none;
          background-color: var(--menu-bg);
          padding-bottom: 1rem;
        }
      }

      .nav-expand {
        display: flex;
        margin: 0 1.5rem 0 auto;

        .nav-hamburger {
          width: 1rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          .nav-hamburger-line {
            width: 100%;
            height: 2px;
            background-color: var(--menu-text);
            border-radius: 2px;
            transition: all 400ms ease-in-out;

            &::before,
            &::after {
              content: '';
              position: absolute;
              width: 1rem;
              height: 2px;
              background-color: var(--menu-text);
              border-radius: 2px;
              transition: all 400ms ease-in-out;
            }

            &::before {
              transform: translateY(-5px);
            }

            &::after {
              transform: translateY(5px);
            }
          }
        }
      }
    }
  }
}